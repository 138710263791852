<template>
  <div class="my-12 text-center">
    <h1 class="pt-12 font-5xl tracking-wide leading-loose font-semibold text-center uppercase">
      WE'RE HERE TO HELP
    </h1>
    <p class="leading-normal pb-8 text-lg">
      Confused? we’re here for you - choose any help option below.
    </p>
    <div class="flex-wrap flex flex-row sm:flex-no-wrap justify-center content-container">
      <div class="w-full flex flex-col items-center text-center lg:bg-grey py-10 lg:px-10 lg:mr-6 md:mr-6">
        <phone-help
          :class="siteId === 'fuz' || siteId === 'hisp' ? 'text-uniti-gold' : 'text-brand-colour-alt'"
          class="m-auto stroke-current" 
        />
        <p class="font-bold uppercase pt-5 pb-2">
          CALL US NOW
        </p>
        <p class="leading-normal h-32 md:h-36">
          Call our friendly, support team 7 days a week between Monday to Friday - 8:00am to 9:00pm AEST, Saturday & Sunday - 9:00am to 9:00pm AEST.
        </p>
        <a
          class="border-2 rounded-full border-grey-darker lg:text-lg bg-white uppercase no-underline text-black lg:px-8 px-2 py-2 cursor-pointer"
          :href="`tel:${brand.phone}`"
          @click="pushGtmEvent('phone')"
        >
          call us on {{ brand.phone }}
        </a>
      </div>

      <div class="w-full text-center lg:bg-grey py-10 lg:px-8 lg:mr-6 md:mr-6">
        <email-help
          :class="siteId === 'fuz' || siteId === 'hisp' ? 'text-uniti-gold' : 'text-brand-colour-alt'"
          class="m-auto stroke-current" 
        />
        <p class="font-bold uppercase pt-5 pb-2">
          Email us
        </p>
        <p class="leading-normal h-32 md:h-36">
          Submit your request via our Contact Form or alternatively, email us directly at {{ brand.support }}
        </p>
        <a
          class="border-2 rounded-full border-grey-darker lg:text-lg md:h-12 bg-white uppercase no-underline text-black lg:px-8 px-2 py-2 cursor-pointer"
          :href="`mailto:${brand.support}`"
          @click="pushGtmEvent('email')"
        >
          Customer Support
        </a>
      </div>

      <div
        v-if="siteId !== 'pre'"
        class="w-full flex flex-col items-center text-center lg:bg-grey py-10 lg:px-10"
      >
        <moving-help 
          :class="siteId === 'fuz' || siteId === 'hisp' ? 'text-uniti-gold' : 'text-brand-colour-alt'"
          class="m-auto stroke-current" 
        />
        <p class="font-bold uppercase pt-5 pb-2">
          Moving House?
        </p>
        <p
          v-if="siteId === 'fuz'"
          class="leading-normal h-32 md:h-34"
        >
          Take Uniti Internet with you! From moving to changing your products and services, we’ve got you covered.
        </p>
        <p
          v-else
          class="leading-normal h-32 md:h-34"
        >
          Take {{ brand.name }} with you! From moving to changing your products and services, we’ve got you covered.
        </p>
        <a
          class="border-2 rounded-full border-grey-darker lg:text-lg bg-white uppercase no-underline text-black lg:px-8 px-2 py-2 cursor-pointer"
          :href="`tel:${brand.phoneCustCare}`"
          @click="pushGtmEvent('relocation')"
        >
          call us on {{ brand.phoneCustCare }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneHelp from '~/components/Icons/PhoneHelp.vue'
import MovingHelp from '~/components/Icons/MovingHelp.vue'
import EmailHelp from '~/components/Icons/EmailHelp.vue'

export default {
  components: {
    PhoneHelp,
    MovingHelp,
    EmailHelp
  },
  data() {
    return {
      siteId: process.env.siteId,
      brand: process.env.brand
    }
  },
  methods: {
    pushGtmEvent(type) {
      switch (type) {
        case 'phone':
          this.$gtm.pushEvent({ event: 'CALLS' })
          break
        case 'email':
          this.$gtm.pushEvent({ event: 'EMAIL' })
          break
        case 'relocation':
          this.$gtm.pushEvent({ event: 'RELOCATION_CALLS' })
          break
        default:
          break
      }
    }
  }
}
</script>
